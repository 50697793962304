module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bergkvist Siljan","short_name":"BergkvistSiljan","description":"Globala leveranser av högkvalitativa och hållbara träprodukter","start_url":"/","background_color":"#000000","theme_color":"#5e8c30","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe8fa9b5e67dfa59401b6e186758b782"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.bergkvistsiljan.com/graphql","schema":{"typePrefix":"Wp","requestConcurrency":8,"queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"type":{"Post":{"limit":null,"where":"language: \"all\""},"Category":{"where":"language: \"all\""},"DealerCategory":{"where":"language: \"all\""},"Area":{"where":"language: \"all\""},"Office":{"where":"language: \"all\""},"ProductCategory":{"where":"language: \"all\""},"Dealer":{"where":"language: \"all\""},"Product":{"where":"language: \"all\""},"Page":{"where":"language: \"all\""},"Tag":{"where":"language: \"all\""},"Testimonial":{"where":"language: \"all\""},"StaffCategory":{"where":"language: \"all\""},"Staff":{"where":"language: \"all\""},"Menu":{"where":"language: \"all\""},"MenuItem":{"where":"language: \"all\""},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"useGatsbyImage":true,"imageQuality":85,"fallbackImageMaxWidth":1000,"createStaticFiles":true,"generateWebpImages":false,"imageMaxWidth":null,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PLRDZGN","defaultDataLayer":{"type":"function","value":"() => ({\n          cookiesAccepted: !!window.localStorage.getItem('dnt'),\n        })"},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
